// @flow
import React, { Component } from 'react'
import styled from 'styled-components'
import ArrowButton from './iconButtons/Arrow'
import Button from './buttons/ButtonA'
import throttle from 'lodash/throttle'
import Product from './tryList/Product'
import UnknownProduct from './tryList/UnknownProduct'
import NewUnknownProduct from './tryList/NewUnknownProduct'
import logoPng from '../assets/img/flicfit-logo.png'
import titlePng from './img/try-list/title.png'
import { hot } from 'react-hot-loader'
import { RecommendEntity, Review, ReviewQnA } from '../types'
import Modal from './Modal'
import ProductModal from './ProductModal'
import type {RouterHistory} from 'react-router-dom';
import AddNewItemModal from './tryList/AddNewItemModal'

const questionId = 'fitting_log_shoe_7'
let scrollTimerId = 0

type Props = {
  appActions: Object,
  recommendActions: Object,
  choicesAndKarteQrCodeUrl: string,
  favorites: Array<number>, 
  favoriteItems: Array<RecommendEntity>,
  reviews: Array<Review>,
  unknownItems: Array<string>,
  itemEntities: { [?number]: RecommendEntity },
  history:RouterHistory
}

type State = {
  selectedProductId: ?number,
  selectedUnknownItem: ?number,
  AddNewItem: boolean,
  unknownShoeName: Array<string>,
  scrolling: boolean,
  qna: ?ReviewQnA,
  purchases:  Array<number>,
}

class TryList extends Component<Props, State> {
   trottledHandleScroll: () => void

  state = {
    selectedProductId: null,
    selectedUnknownItem: null,
    AddNewItem: false,
    unknownShoeName: ['', '', '',''],
    scrolling: false,
    qna : null, 
    purchases: [],
  }
  
  constructor(props: any) {
    super(props)
    this.trottledHandleScroll = throttle(this.handleScroll, 100)
  }

  handleScroll = () => {
    if (scrollTimerId) {
      clearTimeout(scrollTimerId)
    }
    if (!this.state.scrolling) {
      this.setState({ scrolling: true })
    }
    scrollTimerId = setTimeout(() => {
      this.setState({ scrolling: false })
    }, 200)
  }


  handleLogout = () => {
    this.props.appActions.refresh()
  }

  handleFitStart =  (itemId: string, value: string)  => () => {
    // 合う
    console.log(itemId, value)
    this.props.recommendActions.onUpdateFitReview(itemId, value)
    this.props.history.push(`/review/impressions/${itemId}`)
  }

  handleUnfitStart =  (itemId: string, value: string)  => () => {
    // 合わない -> 履き心地入力へ
    this.props.recommendActions.onUpdateFitReview(itemId, value)
    this.props.history.push(`/review/impressions/${itemId}`)
  }

  handleDetailStart =  (itemId: string)  => () => {
    // 履き心地入力へ
    this.props.history.push(`/review/impressions/${itemId}`)
  }

  handleTryStart =  (itemId: string)  => () => {
    this.props.history.push(`/review/footsurvey/${itemId}`)
  }

  handleUnknownReviewStart =  (unknownShoeName: string)  => () => {
    this.props.history.push(`/review/footsurvey/${unknownShoeName}`)
  }

  handleAddUnknownShoe =  ()  => () => {
    this.setState ( { AddNewItem: true })
  }

  handleAddTrylist =  (itemId: number)  => () => {
    this.setState({selectedProductId : itemId})
  }

  handleComplate =  async() => {
    const { recommendActions, history, appActions } = this.props
    const questionId = 'fitting_log_shoe_8'

    let tmpReviews = this.props.reviews.map ( review => {
      console.log(review)
      let tmpReview = {...review}
      let idx = tmpReview.content.shoeReview.findIndex(e => e.questionId == questionId)
      let value = ""
      if ( tmpReview.content.shoeReview[idx]) {
        value = tmpReview.content.shoeReview[idx]['options'][1]
        const itemId = Number ( tmpReview.itemId)
        if ( this.state.purchases.includes (itemId) ) value = tmpReview.content.shoeReview[idx]['options'][0]
        tmpReview.content.shoeReview[idx]['answer'] =  value
      }
      return tmpReview
    })

    // 購入APIの実行
    let actions = []
    tmpReviews.forEach( (e) => {
      actions.push(recommendActions.onRegisterReview(e))
    })
    const res = await Promise.all(actions)

    //recommendActions.onUpdateReviews(tmpReviews)
    recommendActions.onUpdateReviews([])
    recommendActions.onResetFavorite()
    //this.props.history.push(`/purchase`)
    this.props.history.push(`/review/complete`)
  }

  handlePurchase =  (itemId: number)  => () => {
    console.log("humdlePurhase")
    let tmpPurchases = [...this.state.purchases]
    if(tmpPurchases.includes(itemId)) {
      tmpPurchases = tmpPurchases.filter(e => e !== itemId)
    } else {
      tmpPurchases.push(itemId)
    }
    this.setState({purchases: tmpPurchases})
  }


  handleModalClose = () => {
    this.setState({ selectedProductId: null,AddNewItem: false  })
  }

  handleAddUnknownShoeOkbtn =  () => {
    this.props.recommendActions.onUpdateUnknownShoeName(this.state.unknownShoeName.join('_'))
    this.setState({ selectedProductId: null,AddNewItem: false  })
  }

    // 試着リスト（facoriteItems）に追加
  handlefavoriteItems = (items:Array<number>) => () => {

    items.forEach(e => {
      if (!this.props.favorites.includes(e)) {
        this.props.recommendActions.onSelect(e)
      }
    })
    this.setState({ selectedProductId: null,AddNewItem: false  })
  }

  handleDeletefavoriteItem = (itemId: number) =>  () => {
        this.props.recommendActions.onDeleteFavorite(itemId)

  }

  handleTryList = () =>  () => {
    const { appActions } = this.props
    this.handleModalClose()
  }

  handleAddUnknownShoeData =  (key: string, value: string)  => () => {
    let tmpUnknownShoeName = [...this.state.unknownShoeName]
    switch (key) {
      case 'size':
        tmpUnknownShoeName[1] =  value
        break;
      case 'color':
        tmpUnknownShoeName[3] =  value
        break;
      default:
        break;
    }
    this.setState({unknownShoeName: tmpUnknownShoeName })
  }

  handleAddUnknownShoeProductText =  (e: any)  => {
    let tmpUnknownShoeName = [...this.state.unknownShoeName]
    tmpUnknownShoeName[2] =  e.target.value
    this.setState({unknownShoeName: tmpUnknownShoeName })
  }
   handleAddUnknownShoeBrandText =  ( e: any)  => {
    let tmpUnknownShoeName = [...this.state.unknownShoeName]
    tmpUnknownShoeName[0] =  e.target.value
    this.setState({unknownShoeName: tmpUnknownShoeName })
  }
  handleBack = () => {
    // 戻る前に、購入データをセットする 
    const questionId = 'fitting_log_shoe_8'
    let tmpReviews = this.props.reviews.map ( review => {
      let tmpReview = {...review}
      let idx = tmpReview.content.shoeReview.findIndex(e => e.questionId == questionId)
      let value = ""
      if ( tmpReview.content.shoeReview[idx]) {
        value = tmpReview.content.shoeReview[idx]['options'][1]
        const itemId = Number ( tmpReview.itemId)
        if ( this.state.purchases.includes (itemId) ) value = tmpReview.content.shoeReview[idx]['options'][0]
        tmpReview.content.shoeReview[idx]['answer'] =  value
      }
      return tmpReview
    })
    this.props.recommendActions.onUpdateReviews(tmpReviews)
    this.props.history.push('/recommned')
  }

  handleFootSurvey = () => {
    this.props.history.push(`/review/footsurvey`)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.trottledHandleScroll, false)
    this.props.appActions.dataLayerPush({ pageID: 'trylist' })

    // 購入リストを生成する
    const questionId = 'fitting_log_shoe_8'

    let purchases = [] 
    this.props.reviews.forEach( review => {
      let tmpReview = {...review}
      let idx = review.content.shoeReview.findIndex(e => e.questionId == questionId)
      if ( !review.content.shoeReview[idx]) return
      if ( review.content.shoeReview[idx]['answer']) {
        if ( review.content.shoeReview[idx]['options'][0] == review.content.shoeReview[idx]['answer'] ){
          purchases.push(Number(review.itemId))
        }
      }
    })
    this.setState({purchases: purchases})
  }

  render() {
    const { appActions, 
            recommendActions, 
            itemEntities, 
            favoriteItems, 
            unknownItems, 
            favorites, 
            reviews,history } = this.props
    return (
      <Root>
        <Top className='scroll'>
          <Title>
            <img
              src={titlePng}
              alt="試着をご希望の商品"
              width="266"
              height="28"
            />
          </Title>
          <TitlePurchase>購入</TitlePurchase>
          <Products>
            {favoriteItems.map(item => {
              return (
                <li key={item.itemId}>
                  <Product
                    data={{
                      itemId: item.itemId,
                      maker: item.brandName,
                      productNumber: item.productNumber,
                      name: item.productName,
                      size: `${item.makerSize}${item.unit}`,
                      width: `${item.makerWidth}`,
                      color: item.makerColor,
                      material: item.makerMaterial,
                      price: item.price
                    }}
                    label={item.fit}
                    image={item.pictureUri[0]}
                    handleAddTrylist={this.handleAddTrylist}
                    handleFitStart={this.handleFitStart}
                    handleUnfitStart={this.handleUnfitStart}
                    handleDetailStart={this.handleDetailStart}
                    handleDeleteTrylist={this.handleDeletefavoriteItem}
                    handlePurchase={this.handlePurchase}
                    review={reviews.find( e => e.itemId == `${item.itemId}`)}
                    purchase={this.state.purchases.includes(item.itemId)}
                  />
                </li>
              )
            })}

            {/* 
             レコメンドなし商品は今回は登録させない
            {unknownItems.map(item => {
              return (
                <li key={item}>
                  <UnknownProduct
                    unknownShoeName={item}
                    handleBuy={this.handlePurchase}
                    handleReviewStart={this.handleUnknownReviewStart}
                    review={reviews.find( e => e.unknownShoeName == `${item}`)}
                  />
                </li>
              )
            })}

            <li key={'newUknown'}>
              <NewUnknownProduct
                handleAddUnknownShoe={this.handleAddUnknownShoe}
              />
            </li>
            */}

          </Products>
        </Top>
        <BackButton
          dir="previous"
          onClick={this.handleBack }
          color="grey2"
        >
        リコメンドに戻る
        </BackButton>
        <CompleteButton onClick={this.handleComplate} disabled={(reviews.length > 0) ? false: true} color="yellow">試着メモ入力完了</CompleteButton>
        <FootSurveyButton
          dir="previous"
          onClick={this.handleFootSurvey}
        >
        お客様情報を入力する
        </FootSurveyButton>

        {this.state.selectedProductId !== null && (
          <Modal
            handleOutsideClick={this.handleModalClose}
            render={() => (
              <ProductModal
                onClose={this.handleModalClose}
                onTry={this.handleTryList}
                handleSelectTag={(
                  selectedTag: string,
                  selectedLabel: string
                ) => {
                  return
                }}
                handleSelectItems={this.handlefavoriteItems}
                item={itemEntities[this.state.selectedProductId]}
                getSameSizeItemsAndReviews={recommendActions.getSameSizeItemsAndReviews}
                dataLayerPush={appActions.dataLayerPush}
                favorites={favorites}
              />
            )}
          />
        )}

        {this.state.AddNewItem  && (
          <Modal
            handleOutsideClick={this.handleModalClose}
            render={() => (
              <AddNewItemModal
                onClose={this.handleModalClose}
                unknownShoeName={this.state.unknownShoeName}
                onAdd={this.handleAddUnknownShoeOkbtn}
                handleChangeData={this.handleAddUnknownShoeData}
                handleChangeDataBrandText={this.handleAddUnknownShoeBrandText}
                handleChangeDataProductText={this.handleAddUnknownShoeProductText}
              />
            )}
          />
        )}
      </Root>
    )
  }
}

export default hot(module)(TryList)

const Root = styled.div`
  top: 0;
  left: 0;
  background-color: #f3f3f3;
`

const BackButton = styled(Button)`
  position: fixed;
  bottom: 15px;
  left: 15px;
`

const FootSurveyButton = styled(Button)`
  position: fixed;
  bottom: 15px;
  right: 200px;
`

const CompleteButton = styled(Button)`
  position: fixed;
  bottom: 15px;
  right: 25px;
`

const Top = styled.div`
  width: 100%;
  overflow-y: visible;
  -webkit-overflow-scrolling: touch;

`

const Title = styled.div`
  padding: 40px 0 20px 0;
  text-align: center;
`

const TitlePurchase = styled.div`
  padding: 0 0 20px 55px;
  font-weight: bold;
`


const Products = styled.ul`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  &:after {
    content: ' ';
    min-width: 5px;
  }
  li {
    margin: 10px 22px;
    /* flex: 1 1 auto; */
  }
`