// @flow
import React, { Component } from 'react'
import styled from 'styled-components'
import Thumbnail from './thumbnail/MainThumbnail'
import { RecommendEntity } from '../types'

type Props = {
  items: Array<RecommendEntity>,
  handleSelect: (uuid: number ) => () => void,
  isSelected: (uuid: number) => boolean,
  favorites: Array<number>
}

class RecommendItems extends Component<Props, {}> {
  shouldComponentUpdate(nextProps: any) {
    return (
      nextProps.favorites.length !== this.props.favorites.length ||
      nextProps.items.length !== this.props.items.length
    )
  }

  render() {
    const { items, handleSelect, isSelected } = this.props
    let count = 0

    return (
      <Root>
        {items.map(item => {
          count++
          let classNameStr = 'small'
          {/* 
          let classNameStr = count % 2 === 0 ? 'large' : 'small'
          classNameStr += ' '
          classNameStr += count % 3 === 2 ? 'center' : ''
          */}
          return (
            <li key={item.itemId} className={classNameStr}>
              <Thumbnail
                src={item.pictureUri[0]}
                fit={item.fit}
                //type={count % 2 === 0 ? 1 : 0}
                type={0}
                price={item.price}
                selected={isSelected(item.itemId)}
                size={`${item.makerSize}${item.unit}`}
                onSelect={handleSelect(item.itemId)}
              />
              <Infos>
                <dl>
                  <dt>ブランド</dt>
                  <dd>{item.brandName}</dd>
                  <dt>品番</dt>
                  <dd>{item.productNumber}</dd>
                  <dt>サイズ</dt>
                  <dd>{`${item.makerSize}${item.unit}`}</dd>
                  <dt>色</dt>
                  <dd>{item.makerColor}</dd>
                </dl>
              </Infos>
            </li>
          )
        })}
      </Root>
    )
  }
}

export default RecommendItems

const Root = styled.ul`
  width: 955px;
  margin: 0 auto;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: 300px 1fr 300px;
  grid-auto-rows: 420px;
  > li {
    margin-right: auto;
    margin-left: auto;
    &.center {
      margin-top: auto;
    }
    &.small.center {
      margin-bottom: 20px;
    }
  }
`

const Infos = styled.div`
  font-size: 13px;
  color: #00000;
  text-align: left;
  font-weight: bold;
  margin-left: 30px;
  > dl > dt {
	  float: left;
	  clear: left;
	  margin-right: 0.5em;
	  width: 60px;
  }
  > dl > dd {
	    float: left;
	    margin-left: 1em;
  } 
`