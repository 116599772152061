import object3d from './object3d';
import image from './image';
import glTextures from './glTextures';
import glUtils from './glUtils';
import glType from './glType';
import glParam from './glParam';


export default class introRoundRect extends object3d {

  constructor(opt) {

    super(opt)

    this._mesh = undefined
    this._anm = undefined

    this.size = undefined

  }


  init() {

    super.init();

    this._anm = {
      rate:{value:0}
    }
    this.size = new THREE.Vector2();

    this._mesh = new THREE.Mesh(
      this._makeGeo(200, 50, 10),
      new THREE.MeshBasicMaterial({
        color:this.opt.color
      })
    );
    this.add(this._mesh);

    this.resize();

  }


  setSize(w, h, radius) {

    this._mesh.geometry.dispose();
    this._mesh.geometry = this._makeGeo(w, h, radius);

    this._mesh.position.x = -w;

    this.size.set(w, h);
  }




  update() {

    super.update();

    if(!glParam.isRender[glType.SCENE.INTRO] || !this.visible) {
      return;
    }

    if(this.opt.isBg) {

      const angle = this._anm.rate.value * 180;
      const rate = Math.sin(glUtils.radian(angle));

      const w = this.size.x * glUtils.map(rate, 0.1, 1, 0, 1);
      this._mesh.geometry.dispose();
      this._mesh.geometry = this._makeGeo(w, this.size.y, this.size.y * 0.5);

    }


  }


  resize() {

    super.resize();

  }


  reset(opt) {

    this.size.set(glUtils.random(100, 200), 21);

    TweenMax.killTweensOf(this.scale);
    TweenMax.killTweensOf(this.position);
    TweenMax.killTweensOf(this._anm.rate);

    // this.position.x = -this.sw * 0.5;
    this.position.y = glUtils.range(this.sh * 0.5);

    // const duration = glUtils.random(3, 6);
    const duration = glUtils.random(4, 7);
    const delay = opt.delay;

    this.position.x = -this.sw * 0.5 - 100;
    TweenMax.to(this.position, duration, {
      x:this.sw * 0.5 + 50,
      delay:delay,
      ease:Power4.easeInOut,
      onComplete: () => {
        this.reset({
          delay:0
        });
      }
    });

    this._anm.rate.value = 0;
    TweenMax.to(this._anm.rate, duration, {
      value:1,
      delay:delay,
      ease:Power4.easeInOut
    });

    // this.scale.x = 0.1;
    // TweenMax.to(this.scale, duration * 0.5, {
    //   x:4,
    //   ease:Power4.easeIn,
    //   delay:delay,
    //   onComplete: () => {
    //     TweenMax.to(this.scale, duration * 0.5, {
    //       x:0.1,
    //       ease:Elastic.easeOut.config(1, 0.8)
    //     });
    //   }
    // })

  }


  _makeGeo(width, height, radius) {

    const shape = new THREE.Shape();

    const x = 0;
    const y = 0;

    shape.moveTo(x + radius, y);
    shape.lineTo(x + width - radius, y);
    shape.quadraticCurveTo(x + width, y, x + width, y + radius);
    shape.lineTo(x + width, y + height - radius);
    shape.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    shape.lineTo(x + radius, y + height);
    shape.quadraticCurveTo(x, y + height, x, y + height - radius);
    shape.lineTo(x, y + radius);
    shape.quadraticCurveTo(x, y, x + radius, y);
    shape.closePath();

    return new THREE.ShapeBufferGeometry(shape);

  }





}
