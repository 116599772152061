import vert from './shader/base.vert';
import frag from './shader/readyDesc.frag';

import glConf from './glConf';
import object3d from './object3d';
import image from './image';
import glTextures from './glTextures';
import glUtils from './glUtils';
import glParam from './glParam';
import glType from './glType';
import capture from './capture';
import webgl from './index'


export default class readyDesc extends object3d {

  constructor(opt) {

    super(opt)

    this._imgContainer = undefined
    this._guide = undefined
    this._guide2 = []
    this._foot = undefined
    this._text = undefined
    this._last = undefined

  }


  init() {

    super.init();

    this._imgContainer = new THREE.Object3D();
    this.add(this._imgContainer);

    // 足
    this._foot = []
    for(let i = 0; i < 2; i++) {
      const tex = glTextures.get(glConf.PATH_IMG + 'ready_desc_foot_' + i + '.png')
      tex.magFilter = tex.minFilter = THREE.NearestFilter
      const foot = new image({
        tex:tex,
        origin:'center'
      })
      foot.init();
      this._imgContainer.add(foot);
      foot.alpha(0.01);
      this._foot.push(foot);
    }

    // ガイド
    const tex = glTextures.get(glConf.PATH_IMG + 'ready_desc_guide_0.png')
    tex.magFilter = tex.minFilter = THREE.NearestFilter
    this._guide = new image({
      tex:tex,
      origin:'center'
    })
    this._guide.init();
    this._imgContainer.add(this._guide);
    this._guide.alpha(0.01);

    // ガイド テキスト
    for(let i = 1; i <= 2; i++) {
      const gt = new image({
        tex:glTextures.get(glConf.PATH_IMG + 'ready_desc_guide_' + i + '.png'),
        origin:'center'
      })
      gt.init()
      this._imgContainer.add(gt);
      gt.alpha(0.01);
      this._guide2.push(gt)
      gt.position.z = 2.1
    }

    // 最初のテキスト
    this._text = new image({
      tex:glTextures.get(glConf.PATH_IMG + 'ready_desc_text_0.png'),
      origin:'center'
    })
    this._text.init();
    this._imgContainer.add(this._text);
    this._text.alpha(0.01);

    // 最後のテキスト
    this._last = new image({
      tex:glTextures.get(glConf.PATH_IMG + 'ready_desc_text_1.png'),
      origin:'center'
    })
    this._last.init();
    this.add(this._last);
    this._last.alpha(0.01);

    this.visible = false;

    this.resize();

  }


  show(opt) {

    this.visible = true;

    let scale = 1.5;
    let duration = 2;
    let delay = opt.delay;
    let ease = Power4.easeOut;

    // まずガイド
    this._guide.zoomIn({
      scale:scale,
      duration:duration,
      delay:delay,
      ease:ease
    });

    // つぎに足
    delay += 0.5;
    for(let i = 0; i < this._foot.length; i++) {
      this._foot[i].zoomIn({
        scale:scale,
        duration:duration,
        delay:delay + (i * 0.5),
        ease:ease
      });
    }

    delay += 1;
    this._text.zoomIn({
      scale:1.25,
      duration:duration,
      delay:delay,
      ease:ease
    });

    // delay += 0.5;
    for(let i = 0; i < this._guide2.length; i++) {
      this._guide2[i].fadeIn({
        duration:1,
        delay:delay + (i * 0.5),
        ease:Sine.easeInOut
      });
    }

  }


  hide(opt) {

    const duration = 1;
    const scale = 0.9;
    let delay = opt.delay;
    const ease = Power4.easeInOut;

    for(let i = 0; i < this._foot.length; i++) {
      this._foot[i].zoomOut({
        scale:scale,
        duration:duration,
        delay:delay,
        ease:ease
      });
    }

    delay += 0.15;
    this._guide.zoomOut({
      scale:scale,
      duration:duration,
      delay:delay,
      ease:ease
    });

    delay += 0.15;
    this._text.zoomOut({
      scale:scale,
      duration:duration,
      delay:delay,
      ease:ease
    });

    delay += 0.15;
    for(let i = 0; i < this._guide2.length; i++) {
      this._guide2[i].zoomOut({
        scale:scale,
        duration:duration,
        delay:delay,
        ease:ease
      });
    }

  }


  startComplete(opt) {

    // 最後のメッセージ出す
    this._last.zoomIn({
      scale:1.5,
      duration:1,
      delay:opt.delay,
      ease:Elastic.easeOut.config(1, 0.8),
      onComplete:() => {
        this._last.zoomOut({
          scale:0.5,
          duration:1,
          delay:3.5,
          ease:Power4.easeInOut
        })
      }
    })

  }


  update() {

    super.update();

    if(!glParam.isRender[glType.SCENE.READY] || !this.visible) {
      return;
    }



  }


  resize() {

    super.resize();

    this._foot.forEach((val) => {
      val.setSize(this.sw, this.sw);
    })

    this._guide2.forEach((val) => {
      val.setSize(this.sw, this.sw);
    })

    this._guide.setSize(this.sw, this.sw);
    this._text.setSize(this.sw, this.sw);
    this._last.setSize(this.sw, this.sw);

  }


}
