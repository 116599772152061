// @flow
import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import RecommendMenu from './RecommendMenu'
import SearchResultTitle from './searchResult/SearchResultTitle'
import ButtonA from './buttons/ButtonA'
import ProductModal from './ProductModal'
import SelectedItems from './searchResult/SelectedItems'
import RecordInfo from './searchResult/RecordInfo'
import Modal from './Modal'
import { formatSize } from '~/helpers/number'
import RecommendItems from './RecommendItems'
import throttle from 'lodash/throttle'
import NoRecommendItems from './NoRecommendItems'
import type {RouterHistory} from 'react-router-dom';
import {
  RecommendEntity,
  Tags,
  Measurement,
  NonSelectTagItems,
  SelectedTagItems
} from '../types'
import { hot } from 'react-hot-loader'

let scrollTimerId = 0

type Props = {
  digitizerCode: string,
  footId: string,
  visitDate: string,
  items: Array<RecommendEntity>,
  appActions: Object,
  recommendActions: Object,
  webglActions: Object,
  tags: Tags,
  measurement: Measurement,
  jis: {
    jisSize: string,
    jisWidth: string
  },
  jisWidthDisplay: boolean,
  nonSelectTagItems: NonSelectTagItems,
  selectedTagItems: SelectedTagItems,
  favoriteItems: Array<RecommendEntity>,
  favorites: Array<number>,
  itemEntities: { [?number]: RecommendEntity },
  history: RouterHistory
}

type State = {
  selectedProductId: ?number,
  load: boolean,
  scrolling: boolean
}

class Recommend extends Component<Props, State> {
  trottledHandleScroll: () => void

  state = {
    selectedProductId: null,
    load: false,
    scrolling: false
  }

  constructor(props: any) {
    super(props)
    this.trottledHandleScroll = throttle(this.handleScroll, 100)
  }

  isSelected = (itemId: number) => {
    return this.props.favorites.includes(itemId)
  }

  handleScroll = () => {
    if (scrollTimerId) {
      clearTimeout(scrollTimerId)
    }
    if (!this.state.scrolling) {
      this.setState({ scrolling: true })
    }
    scrollTimerId = setTimeout(() => {
      this.setState({ scrolling: false })
    }, 200)
  }

  handleModalClose = () => {
    this.setState({ selectedProductId: null })
  }

  // 試着リスト（facoriteItems）に追加
  handlefavoriteItems = (items:Array<number>) => () => {
    items.forEach(e => {
      this.props.recommendActions.onSelect(e)
    })
    this.setState({ selectedProductId: null })
  }

  handleSelect = (itemId: number) => () => {
    this.setState({ selectedProductId: itemId })
  }

  handleDeleteFavorite = (itemId: number) => () => {
    this.props.recommendActions.onDeleteFavorite(itemId)
  }

  handleTryList = () =>  () => {
    const { recommendActions, appActions, favoriteItems, history} = this.props
    // 試着リスト画面へ遷移
    if ( this.state.selectedProductId == null ) {
      history.push('/trylist')
      return
    }
    this.setState({ selectedProductId: null })
  }

  handleSelectTag = (selectedTag, selectedLabel) => {
    this.props.recommendActions.onSelectTag(selectedTag, selectedLabel)
    this.setState({ selectedProductId: null })
  }

  filteredItems = (): Array<RecommendEntity> => {
    const { items, selectedTagItems } = this.props
    if (items == null ) return []
    const selectedTagKeys = Object.keys(selectedTagItems).filter(tag => {
      return selectedTagItems[tag] !== null
    })
    if (selectedTagKeys.length === 0) {
      return items
    }
    return items.filter(item => {
      const ret = []
      for (let i = 0, len = selectedTagKeys.length; i < len; i++) {
        const tag = selectedTagKeys[i]
        const selectedValue = selectedTagItems[tag]
        const itemValue = item[tag]
        ret.push(selectedValue === itemValue)
      }
      return ret.every(r => !!r)
    })
  }

  async componentDidMount() {
    console.log("componentDidMount Recommend" )
    window.addEventListener('scroll', this.trottledHandleScroll, false)
    const { appActions, recommendActions, webglActions } = this.props

    const filteredItems = this.filteredItems()
    if (filteredItems.length > 0 ) return;

    let { digitizerCode, footId, visitDate } = this.props
    appActions.showLoading()
    console.log("RECOCOMEND", visitDate)
    console.log(this.props)

    // 入力データがない場合はLSから取得する。
    if(!digitizerCode){
      const inputDataJson  = localStorage.getItem('input') || '{}'
      const inputData = JSON.parse(inputDataJson)
      digitizerCode = inputData['digitizerCode']
      footId = inputData['footId']
      this.props.appActions.setInput( inputData)
    }

    // initデータ取得
    const { clientCode,shoeImages, tags, target, jisWidthDisplay, karteDataLevel } = await recommendActions.fetchInitialize(digitizerCode)
    appActions.setStoreInfo({clientCode, jisWidthDisplay, karteDataLevel })

    // footUudiの作成
    let footUuid = ""
    let today = ""
    if  (!visitDate) {
      const now = new Date();
      today = now.getFullYear()+ ( "0"+( now.getMonth()+1 ) ).slice(-2)+ ( "0"+now.getDate() ).slice(-2)
    } else {
      today = visitDate
    }
    //footUuid = `${clientCode}-${digitizerCode}-${today}-${footId}`
    //footUuid = "81099990010004-000050-20200325-0003"
    footUuid = "81200270010001-000050-20200518-0002"
    console.log(footUuid)

    // measturementデータの取得
    appActions.setFootUuid(footUuid)
    const { left, right, threeUrl, filter } = await appActions.getMeasurement(footUuid)
    webglActions.registerPlyFileUrl(threeUrl)

    // recommendデータを取得
    let size = left.footMeasurement.footLength
    let width = left.footMeasurement.footBoundingWidth
    if (left.footMeasurement.foot_length < right.footMeasurement.footLength  ) {
      size = right.footMeasurement.footLength
      width = right.footMeasurement.footBoundingWidth
    }
    await recommendActions.registerRecommend({
      size: size,
      width: width,
      filter: filter
      // TODO 検索条件をSET!!
    })
    // JISデータを取得
    await appActions.getJisSize(footUuid)
    appActions.hideLoading()

    this.setState({load: true})

    //this.props.appActions.dataLayerPush({ pageID: 'recommend' })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.trottledHandleScroll)
    if (scrollTimerId) {
      clearTimeout(scrollTimerId)
    }
  }
  render() {
    const {
      appActions,
      jisWidthDisplay,
      jis,
      tags,
      nonSelectTagItems,
      selectedTagItems,
      favoriteItems,
      favorites,
      recommendActions,
      itemEntities,
      history
    } = this.props
    const filteredItems = this.filteredItems()

    return (
      <Root>
        {filteredItems.length > 0 ? (
          <Fragment>
            <Title />
            <RecommendItems
              favorites={favorites}
              items={filteredItems}
              handleSelect={this.handleSelect}
              isSelected={this.isSelected}
            />
          </Fragment>
        ) : (
          this.state.load && (
          <NoRecommendItems />
          )
        )}
        <HeadMenu
          tags={tags}
          nonSelectTagItems={nonSelectTagItems}
          selectedTagItems={selectedTagItems}
          handleSelectTag={(selectedTag: string, selectedLabel: string) => {
            this.handleSelectTag(selectedTag, selectedLabel)
          }}
          handleRemoveTag={recommendActions.onRemoveTag}
          favoriteSize={favorites.length}
        />
        <SelectedItemsStyled
          scrolling={this.state.scrolling ? 1 : 0}
          empty={favorites.length === 0}
          onTry={this.handleTryList}
          favoriteSize={favorites.length}
        />
        <RecordInfoStyled
          scrolling={this.state.scrolling ? 1 : 0}
          onClick={() => {
            // 足カルテへリンク
            history.push('/karte')
          }}
          loggedIn={false}
          jis={jis}
          jisWidthDisplay={jisWidthDisplay}
        />
        <TopButton
          onClick={() => {
            history.push('/')
            //appActions.refresh()
          }}
        >
          TOPに戻る
        </TopButton>
        {this.state.selectedProductId !== null && (
          <Modal
            handleOutsideClick={this.handleModalClose}
            render={() => (
              <ProductModal
                onClose={this.handleModalClose}
                handleSelectTag={(
                  selectedTag: string,
                  selectedLabel: string
                ) => {
                  this.handleSelectTag(selectedTag, selectedLabel)
                }}
                handleSelectItems={this.handlefavoriteItems}
                item={itemEntities[this.state.selectedProductId]}
                dataLayerPush={appActions.dataLayerPush}
                getSameSizeItemsAndReviews={recommendActions.getSameSizeItemsAndReviews}
                favorites={favorites}
              />
            )}
          />
        )}
      </Root>
    )
  }
}

export default hot(module)(Recommend)

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.grey};
  min-height: 100vh;
  margin-top: 75px;
`

const HeadMenu = styled(RecommendMenu)`
  position: fixed;
  top: 0;
  left: 0;
`

const Title = styled(SearchResultTitle)`
  margin: 110px 0 75px 0;
`

const RecordInfoStyled = styled(RecordInfo)`
  position: fixed;
  top: 100px;
  transition: right 0.3s ease;
  right: ${({ scrolling }) => (scrolling ? '-160px' : 0)};
`

const TopButton = styled(ButtonA)`
  position: fixed;
  bottom: 34px;
  left: 15px;
`

const SelectedItemsStyled = styled(SelectedItems)`
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  pointer-events: none;
  transition: bottom 0.3s ease;
  bottom: ${({ scrolling }) => (scrolling ? '-100px' : '25px')};
  margin-left: ${({ favoriteSize }) => (favoriteSize > 6 ? '50px' : '0')};
`